.property {
  &-manager{
    margin-bottom: 100px;
    &__form{
      margin-top: 25px;
      flex-direction: column;
    }
    figure{
      display: flex;
      align-items: center;
      height: 130px;
    }
    figcaption {
      white-space: pre-line;
      font-size: 1.2rem;
      margin-right: 30px;
    }
    .page-modal__title {
      display: block;
      em {
        font-size: .5em;
      }
    }

    .mat-form-field-wrapper{
      width: 100%;
    }
    .mdc-floating-label{
      width: 100%;
      min-height: 25px;
    }
    .mat-mdc-radio-group {
      flex-wrap: wrap;
      border-bottom: 1px solid $rk_grey2;
    }
    .mat-mdc-radio-button{
      position: relative;
      text-align: left;
      padding:5px 10px;
      display: flex;
      margin: 0;
      background: rgba($rk_white, .8);
      height: 55px;
      @include mq(tablet-small) {
        padding:10px 0;
        width: 100%;
      }
    }
    &__section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding:10px 0;
      width: 100%;
      &__item{
        display: flex;
        align-items: center;
        width: 31%;
        @include mq(tablet) {
          width: 48%;
        }
        @include mq(tablet-small) {
          width: 100%;
        }
      }
    }
    &__avatar{
      position: relative;
      margin: 30px 0 0;
      padding: 0;
      height: 150px;
      horus-cropper{
        margin: 0 40px 0 0;
      }
      @include mq(tablet){
        height: 75px;
        margin-bottom: 40px;
      }
      @include mq(tablet-small){
        margin: 0;
        height: 145px;
      }
    }
    &__extrainfo{
      margin-bottom: -20px;
    }
    &__address{
      max-width: 100%;
    }
    &__sameaddress{
      margin:10px 0;
    }

    #autocompleteMap{
      height: 250px;
    }
    &__icon {
      width: 20px;
      margin-right: 10px;
      filter: none;
      @include mq(tablet) {
        margin-left: 20px;
      }
    }
  }
}
