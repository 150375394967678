* {
  box-sizing: border-box;
  outline: none !important;
}

html {
  font-size: 75%;
  text-rendering: geometricPrecision;
  overflow-x: hidden;
}

html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
  line-height: 1;
  font-family: basic, Arial, sans-serif;
  max-width: 100%;
  height: 100%;
}

#main-router + * {
  min-height: 100vh;
  padding-bottom: 50px;
  display: block;
  animation: showRouter .7s linear;
}
#popup + *,
#popup ~ horus-footer {
  display: none;
  opacity: 0;
  max-height: 99vh;
  overflow: hidden;
}

.environment {
  display: none;
  position: fixed;
  width: 100vw;
  z-index: -1;
  opacity: .3;
  font-size: 4vw;
  letter-spacing: -.05em;
  &:before {
    position: absolute;
    top: 61px;
    left: 10px;
    content:  attr(title);
    font-family: headers, Arial, sans-serif;
  }
  &.--dev {
    display: block;
    color: $rk_horus09;
  }
  &.--test {
    display: block;
    color: $rk_horus04;
  }
}

article{
  min-height: calc(100vh - 50px);
  padding:0 5vw;
  height: 100%;
  &.normal{
    min-height: 0;
  }
}
.limited {
  max-width: map_get($breakpoints, 'cinemascope');
  margin:auto;
  article{
    padding: 0;
  }
}

h1,h2,h3,h4,h5,h6 {
  font-family: headers, Arial, sans-serif;
  letter-spacing: -.03em;
}


//-----------HEADER & CONTENT

.--header{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 20px;
  @include mq('tablet') {
    padding: 20px 0;
  }
  &--title {
    font-size: 2.5rem;
    flex-grow: 2;
    display: flex;
    align-items: center;
    margin:0;
    border-style: solid;
    border-image-slice: 1;
    border-width: 0 0 1px;
    border-image-source: linear-gradient(to right, $rk_horus00, rgba($rk_horus03,.4), rgba($rk_black,0));
    height: 42px;
    @include mq('tablet') {
      font-size: 2rem;
    }
    @include mq('phablet') {
      font-size: 1.5rem;
    }
    span{
      padding: 0 10px;
     /* &:nth-of-type(2) {
        filter: brightness(2.2);
      }*/
    }
  }
  &--mini{
    display: inline-block;
    text-align: right;
    font-size: .7em;
    line-height: 1em;
    font-family: basic, Arial, sans-serif;
    @include mq('tablet') {
      font-size: .9em;
    }
  }
  &--split{
    font-size: .8em;
    color:$rk_horus03;
    font-weight: lighter;
    font-family: special, Arial, sans-serif;
  }
}
.horus {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.--content{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}


//-----------ICONS


.--icon{
  width: 25px;
  margin: 0 10px 0 0;
}

.--noavatar{
  background: $rk_grey8;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    height: 60%;
  }
}


//-----------accordion

.accordion {
  @include mq('tablet') {
    &__trigger{
      margin:0 0 20px;
      padding-right: 50px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:after {
        content: "";
        position: absolute;
        right: 1%;
        background: url(/assets/img/icons/expandir.svg) center right no-repeat;
        width: 25px;
        transition: all .05s linear;
        filter: saturate(0);
        height: 25px;
      }
    }
    &__content{
      height: 0;
      max-height: 0;
      margin-bottom:20px;
      overflow: hidden;
      transition: max-height .6s linear;
      border-bottom: 1px solid $rk_grey05;
    }
    &__opened {
      &:after {
        transform: rotate(90deg);
      }
      &+.accordion__content {
        height: auto;
        max-height: 9000px;
      }
    }
  }
}



//-------BLOCK LAYER

.--disabled-style {
  opacity: .5;
  cursor: not-allowed !important;
  .--link{
    cursor: not-allowed !important;
  }
}

.--disabled-container{
  position: relative;
  &__layer{
    position: absolute;
    width: 104%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba($rk_white, .4);
    display: none;
    &:before {
      position: absolute;
      left: 50%;
      top: 10px;
      margin-left: -100px;
      padding: 10px;
      content: "Vista sólo consulta";
      font-size: 1.2rem;
      border-radius: 10px;
      background: rgba($rk_white, .5);
      font-family: headers, Arial, sans-serif;
      opacity: 0;
      transition: opacity .2s linear;
    }
    &:hover:before {
      opacity: 1;
    }
  }
  .--disabled-container__layer{
    display: block;
    cursor: not-allowed;
  }
}

//-------------------RESPONSIVE

.--g-desktop {
  display: unset;
  @include mq(tablet-med) {display: none}
}

.--g-mobile {
  display: none;
  @include mq(tablet-med) {display: unset}
}

@media print {
  horus-header,
  horus-footer
  {display: none}
  .horus{
    overflow: visible;
  }
}



//-------------map

google-map {
  position: absolute;
}
google-map,
.map-container{
  width: 100% !important;
  height: 100% !important;
  display: block;
  top: 0;
  left: 0;
}
.gm-bundled-control-on-bottom {
  bottom:60% !important;
}
.gm-ui-hover-effect{
  transform: scale(1.5);
  margin: 5px !important;
}


//-----utils
//---------------------------flex
.--fcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.--fdc {
  flex-direction:column;
}

.--facenter {
  display: flex;
  align-items: center;
}

.--fjcenter {
  display: flex;
  justify-content: center;
}

.--fjend {
  display: flex;
  justify-content: center;
}

.--faend {
  display: flex;
  align-items: flex-end;
}

.--fjb {
  display: flex;
  justify-content: space-between;
}


.--fw {
  display: flex;
  flex-wrap: wrap;
}

//---------------------------cajas

.--fill {
  position: absolute;
  width: 100%;
  height: 100%;
  top:0; left: 0;
}

.--box{
  padding: 20px;
  border-radius: 20px;
  border: 1px solid $rk_grey2;
  flex-grow: 2;
}
.--sh {
  box-shadow: 0 0 5px rgba($rk_black, .2);
}
