.templates{
  .basic-filters{
    margin: 0;
  }
  &__content{
    margin:0 auto 2vh;
    &:after {
      content: "";
      flex-grow: 2;
    }
    @include  mq(tablet){
      justify-content: space-around;
    }
    &-title {
      margin: 30px 0 30px;
      font-size: 1.7rem;
      color: $rk_horus09;
      width: 100%;
      opacity: .8;
      border-bottom: 1px $rk_horus09 solid;
    }
  }
  &-manager{
    .--disabled {
      fieldset, .profile-form--right {
        filter: saturate(.25);
      }
    }

    .profile-form__container {
      @include mq('tablet') {
        padding-left: 0;
      }
    }
    &__no-report{
      color: $rk_semaphoreRed;
      min-width: 80%;
      margin: 10px 0 20px;
      font-family: headers, Arial, sans-serif;
    }
    .form__warning{
      position: relative;
      font-family: headers, Arial, sans-serif;
      bottom: unset;
      padding: 20px 0;
    }
    &__left{
      width: 100%;
      .profile-form__container {
        padding-left: 0;
      }
    }
    &__clone{
      display: flex;
      font-size: 1.2rem;
      transition: color .1s linear;
      align-items: center;
      width: max-content;
      @include mq('tablet') {
        margin-bottom: 30px;
      }
      &:hover, &:focus{
        color:$rk_copper2;
      }
      img{
        width: 30px;
        margin-right: 10px;
      }
    }
    .form__warning{
      color: $rk_white;
      padding: 0 0 20px;
    }
    &__title {
      width: 100%;
      justify-content: flex-start;
      @include mq(tablet){
        flex-direction: row;
      }
      @include mq(phablet){
        position: relative;
        top: -50px; left:0;
        margin-bottom: -30px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    h2 &__owner {
      position: relative;
      bottom: unset;
      right: unset;
      left:-20px;
      display: inline-block;
      @include mq(phablet){
        left: 0;
        padding-left: 0;
      }
    }
    &__owner-name{
      margin-left: 10px;
      font-size: 1.5rem;
      @include mq(phablet){
        margin-left: 0;
        padding-left: 0 !important;
      }
    }
  }
  &__item-row {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__item{
    position: relative;
    transition: all .1s linear;
    &__media {
      min-width: 40px;
      &-item {
        border: 0;
        margin: 2vh auto;
        max-width: 90%;
        min-width: 400px;
        position: relative !important;
      }
      &-none {
        filter: saturate(0);
      }
      &-open {
        cursor: pointer;
        opacity: .5;
        filter: saturate(0.5);
        &:hover {
          opacity: 1;
          filter: none;
        }
      }
    }
    &__toggle-hide {
      color: $rk_semaphoreGreen;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: default;
      &.--hide {
        filter: saturate(0);
        opacity: .5;
      }
    }
    &__hideicon {
      max-width: 30px;
      cursor: pointer;
    }
    &.editable:hover, &.editable:focus{
      .circle-link {
        transform: scale(.8);
      }
    }
    .--squares &{
      box-shadow: 2px 2px 4px rgb($rk_grey8, .3);
      width: 8vw;
      font-size:.9rem;
      min-width: 165px;
      min-height: 180px;
      border: 1px solid $rk_grey2;
      margin: 0 2vw 2vh 0;
      padding: 10px 20px;
      filter: saturate(0);

      @include  mq(phablet){
        min-width: 135px;
      }
      &__container{
        padding: 10px;
        display: block;
      }
      h3 {
        color: $rk_grey6;
      }
      &.editable{
        filter: none;
        h3 {
          color: inherit;
        }
        &:hover, &:focus{
          a{
            color: $rk_black;
          }
        }
      }
      .templates__owner{
        position: absolute;
        bottom:5px; right:5px;
      }
      &-row{
        width: auto;
      }
      &__media {
        display: none;
      }
    }
    .--tables &{
      border-bottom: 1px solid $rk_grey2;
      font-size:1.2rem;
      margin: 0 auto;
      width: 100%;
      &:hover, &:focus{
        a{
          color: $rk_black;
        }
      }
      &-row{
        .--hidden {
          display: none;
        }
      }
      a{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      h3{
        flex-grow: 2;
        color: unset;
        border: 0;
      }
      .templates__fake-text {
        display: none;
      }
      .templates__owner{
        position: relative;
      }
    }
    &.--done {
      filter: none;
      &:hover {
        transform: none;
        filter: none;
      }
    }
    .circle-link {
      position: absolute;
      top:-25px;
      right:-35px;
      transform: scale(.6);
      background: $rk_grey3;
      &:hover{
        background: $rk_grey8;
        img{
          filter: none;
        }
      }
      img{
        filter: brightness(0);
      }
    }
    .circle-done-link {
      cursor: pointer;
      img{
        right: 0;
        top: 0;
        width: 20px;
        filter: none;
      }
    }
  }
  h3{
    min-height: 36px;
  }
  &__last{
    color: $rk_black;
    font-size: .9em;
    font-family: basic, Arial, sans-serif;
  }
  &__icons-list{
    display: flex;
    img{
      width: 25px;
      margin: 0 10px 0 0;
    }
  }
  &__icons{
    display: flex;
    align-items: center;
    border-bottom: 1px solid $rk_grey3;
    font-family: basic, Arial, sans-serif;
    font-size: 2.2rem;
    color: $rk_grey5;
    padding-bottom: 10px;
    img{
      width: 30px;
      margin: 0 10px 0 0;
    }
  }
  &__fake-text{
    display: block;
    margin:10px 0;
    background-color: $rk_grey05;
    height: 10px;
    width: 100%;
    transition: background-color .2s linear;
  }
  &__owner{
    img {
      max-width: 44px;
    }
  }
  horus-wysiwyg{
    //max-width: 600px;
    display: block;
    margin:0;
  }
  .--uploader-file {
    height: 40px;
    min-width: 0;
    background-size: 35px;
    background-position: center center;
    display: block;
    border-radius: 0;
    width: 64px;
    cursor: pointer;
    border-left: 1px solid $rk_grey3;
  }
  &__attachments{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:5px 10px;
    margin: 20px auto;
    border: 1px solid $rk_grey1;
    h4{
      color:$rk_grey3;
    }
    &-files{
      display: flex;
      flex-grow: 2;
    }
    a{
      width:40px;
      margin:0 30px;
      text-align: center;
      font-size: .8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:hover, &:focus{
        span{
          color:$rk_black;
        }
      }
    }
    span{
      display: block;
      padding-top: 5px;
    }
  }
}

horus-templates-list{
  .--no-results {
    margin: 30px 0 20px;
  }
}
