horus-logs{
  position: relative;
  display: block;
}

.logs{
  flex-basis: 22.5%;
  margin-left: 1.5%;
  padding-left: 1.5%;
  border-left: 1px solid $rk_grey2;
  &.--loading {
    opacity: 0;
  }
  &__list-box {
    position: relative;
  }
  &__list{
    margin: 20px 0;
    font-size: 1.1rem;
  }
  &__item{
    position: relative;
    margin: 5px;
    border-bottom:1px solid $rk_grey05;
    @include mq(tablet-small) {
      flex-wrap: wrap;
      padding: 10px 0;
    }
    > span {
      color: $rk_grey5;
      padding: 5px;
      flex-basis: 14%;
      @include mq(tablet-small) {
        flex-basis: calc(100% - 125px);
      }
    }
    .--log {
      flex-grow: 2;
      @include mq('tablet-small') {
        padding:0 60px 5px 0;
        max-width: 100%;
      }
    }
  }
  &__header {
    flex-wrap: wrap;
    > span {
      font-size: 1.2rem;
      color: $rk_black;
      display: flex;
      @include mq(tablet-small) {
        display: none;
      }
    }
  }
  &__filters {
    width: 100%;
    .basic-filters {
      margin: 20px 0;
      background: none;
      border: 0;
    }
  }
  &__resp-sort {
    display: none !important;
    width: 100%;
    @include mq(tablet-small) {
      display: flex !important;
    }
    .--resp {
      position: absolute;
      right: 0;
      left: unset;
      width: 25px;
      bottom: 26px;
    }
    .mat-mdc-form-field{
      width: calc(100% - 40px);
    }
  }
  &__order {
    width: 13px;
    position: relative;
    left: 5px;
    cursor: pointer;
    display: none;
    &.--dir1{
      display: inline;
      transform: rotate(180deg);
    }
    &.--dir-1{
      display: inline;
    }
  }
  &__name{
    display: flex;
    align-items: center;
    flex-grow: 2;
    font-weight: bold;
    line-height: .9;
    color: $rk_grey8 !important;
    transition: color .2s linear;
  }
  &__contact {
    flex-grow: 2;
    &.--dossier{
      font-weight: bold;
      color: $rk_black;
    }
    a:hover, .--aslink:hover {
      color: $rk_loader1;
    }
  }
  &__sentby{
    width: 17px;
    margin: 0 5px;
    filter: brightness(0.7) saturate(0);
  }
  &__link {
    user-select: none;
    cursor: pointer;
    &:hover {
      color: $rk_grey4;
    }
  }
  &__score {
    justify-content: flex-end;
    font-size: 2rem;
    font-family: headers, Arial, sans-serif;
    flex-basis: calc(20% - 50px);
    small {
      font-size: .6em;
      font-family: basicregular, Arial, sans-serif;
    }
    @include mq(tablet-small) {
      position: absolute;
      right: 25px;
      font-size: 1.7rem;
    }
  }
  &__info {
    width: 25px;
    min-width: 25px;
    max-width: 25px;
    margin: 2px 0 0 0;
    @include mq(tablet-small){
      position: absolute;
      right: 0;
    }
  }
  &__desc {
    position: absolute;
    top:30px; width: 100%;
    background: $rk_white;
    opacity: 0;
    transition: opacity .2s linear .5s;
    box-shadow: 2px 2px 10px $rk_grey5;
    border-radius: 5px;
    padding: 15px;
    font-size: 1.3rem;
    display: none;
    right: 0;
    max-width: 600px;
    @include mq(tablet){
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      min-height: 100vh;
    }
    h5, h6 {
      margin:0;
    }
    &__title {
      color: $rk_grey6;
      display: flex;
      justify-content: space-between;
      font-size: 1.3rem;
      max-width: calc(100% - 40px);
      span {
        font-family: basic, Arial, sans-serif;
        font-size: 1.2rem;
        font-weight: lighter;
      }
    }
    &__receivers{
      padding: 10px 0 0;
      margin: 10px 0;
      border: solid $rk_grey4;
      border-width: 1px 0;
      li{
        font-size: 1.3rem;
      }
    }
    .--detail & {
      display: block;
      opacity: 1;
      z-index: z(level3);
    }
    &__description{
      color: $rk_grey6;
      font-size: 1.1rem;
      width: 100%;
      padding-top: 10px;
      word-break: break-word;
      &:nth-of-type(3){
        padding-top: 10px;
        border-top:1px solid $rk_grey2;
      }
      img{
        max-width: 100%;
      }
    }
    &__close {
      width: 25px;
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
}

.sentby__item{
  &:before{
    margin: 0 5px 0 0;
    content: "";
    min-width: 20px;
    min-height: 20px;
    display: inline-block;
    border-radius: 50%;
    z-index: z(floor);
    @include mq('tablet-small') {
      left: -8px;
    }
  }
}

.--log-action:before{
  background: $rk_action2;
}

.--log-special:before{
  background: $rk_step4;
}
