.--toolnav{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  animation: appear .2s linear;
  margin-top: -4px;
  &:hover .active,
  &:focus .active{
    figcaption {
      opacity: 0;
    }
  }
  figure{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
  }
  .--link {
    margin:0 5px;
    border: 1px solid rgba( $rk_loader1, .5);
    border-radius: 50%;
    @include  mq(phablet){
      margin:3px;
    }
    &:hover, &:focus , &.active{
      border: 1px solid $rk_grey8;
      background: $rk_grey8;
    }
    &:hover{
      figcaption{
        opacity: 1;
      }
    }
    img {
      width: 60%;
      transition: filter .05s linear;
    }
  }
  .active{
     figcaption{
       opacity: 1;
       font-family: headers, Arial, sans-serif;
     }
  }
  figcaption {
    position: absolute;
    top: -14px;
    width: 90px;
    text-align: center;
    color: $rk_grey6;
    @include  mq(desktop){
      visibility: hidden;
      margin-top:-3000px;
    }
  }
}

.--big-toolnav {
  display: flex;
  align-items: center;
  width: 90%;
  margin-top: 10vh;
  justify-content: space-evenly;
  flex-wrap: wrap;
  figure{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width:6vw;
    min-width: 90px;
    margin: 0 2vw;
  }
  figcaption{
    display: block;
    opacity: 1;
    margin-top: 10px;
    text-align: center;
    @include mq('tablet') {
      margin-left: 0;
    }
  }
}
